/* stylelint-disable */
@import '../../../utils/styles/_colors';
@import '../../../utils/styles/_mixins';

.atom__section-container {
  position: relative;

  max-width: 100%;

  margin: auto;

  @include phone {
    &[data-full-screen='false'] {
      padding: 0 $grid-padding;
    }
  }

  @include gt-phone {
    &[data-full-screen='false'] {
      max-width: $grid-width-desktop;
    }
    &[data-full-screen='true'] {
      max-width: $bp-desktop;
    }
  }

  @include tablet {
    padding: 0 $grid-padding;
  }

  /* GRID
  ========================================================================== */
  &[data-grid='true'] {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include gt-tablet {
      grid-column-gap: $grid-gutter;
      grid-template-columns: repeat(12, 1fr);
    }
  }

  /* DECORATORS
  ========================================================================== */
  .section-decorator-left {
    position: relative;
  }

  .section-content {
    position: relative;
  }

  .section-decorator-right {
    position: relative;
  }
}
