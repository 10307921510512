// !!IMPORTANT!! Font family definitions
@font-face {
  font-family: Toyota;
  src: local(Toyota),
       url(/fonts/Toyota/ToyotaType-Black.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota;
  src: local(Toyota),
       url(/fonts/Toyota/ToyotaType-Black.woff2) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota;
  src: local(Toyota),
       url(/fonts/Toyota/ToyotaType-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota;
  src: local(Toyota),
       url(/fonts/Toyota/ToyotaType-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota;
  src: local(Toyota),
       url(/fonts/Toyota/ToyotaType-Regular.woff2) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota;
  src: local(Toyota),
       url(/fonts/Toyota/ToyotaType-Semibold.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Toyota;
  src: local(Toyota),
       url(/fonts/Toyota/ToyotaType-Semibold.woff2) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
// !!IMPORTANT!! End of Font family

// !!IMPORTANT!! Default body font family definition
html, body {
  font-family: Toyota, Arial, Helvetica, sans-serif;
}
// !!IMPORTANT!! End of Default body font family definition