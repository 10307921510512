@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.skip-links {
  
  .skip-links__anchor {

    &:focus {
      z-index: $z-index-moon; 
      top: 3%; left: 2%;
      padding: 1rem;
      width: auto; height: auto; 
      clip: initial;
      opacity: 1;
      font-size: 1.4rem; color: $c-base-white; font-weight: bold; text-transform: uppercase; text-decoration: none;
      background-color: $c-base-black;
    }
  
  }

}
