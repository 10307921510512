@import '../../../utils/styles/colors';
@import '../../../utils/styles/mixins';

.icon-download-container {
  position: relative;

  width: $s-s;
  height: $s-s;

  /* VARIANT SIZES
  ========================================================================== */
  &[data-size="medium"] {
    width: $s-md;
    height: $s-md;
  }

  &[data-size="large"] {
    width: $s-lg;
    height: $s-lg;
  }

  &[data-size="extra-large"] {
    width: $s-xl;
    height: $s-xl;
  }

  stroke: $c-base;

  /* VARIANT COLORS
  ========================================================================== */
  &[data-color="primary"] {
    stroke: $c-primary;
  }

  &[data-color="white"] {
    stroke: $c-base-white;
  }
}
