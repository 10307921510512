// !!IMPORTANT!! Creator dynamic content start

:root {
  --primary-light: #CAF4FF;
  --primary-intense: #dd1f26;
  --primary: #00708D;
  --primary-dark: #003449;
  --link-default: #00708D;
  --link-hover: #003449;
  --link-disabled: #97A7B1;
  --link-default-dark: #FFFFFF;
  --link-hover-dark: #97A7B1;
  --link-disabled-dark: #4C5D68;
  --secondary: #000;
  --state-success: #7dcb61;
  --state-success-dark: #598f45;
  --state-warn: #f9d067;
  --state-warn-dark: #d6a832;
  --state-danger: #da2222;
  --state-danger-dark: #eb0a1e;
  --base-text: #1f1f1f;
  --base-link: #1f1f1f;
  --base-bg: #fbfbfb;
  --base-lightest: #fff;
  --base-lighter: #f9f9f9;
  --base-light: #f0f0f0;
  --base: #1f1f1f;
  --base-dark: #999;
  --base-darker: #777;
  --base-darkest: #1f1f1f;
  --base-black: #1f1f1f;
  --base-white: #fff;
  --gray-1: #F1F2F4;
  --gray-2: #E8EBED;
  --gray-3: #d3d9dd;
  --gray-4: #97a7b1;
  --gray-5: #69808F;
  --gray-6: #4c5d68;
  --gray-7: #595a58;
  --blue-1: #00a3b7;
  --blue-2: #397dc0;
  --blue-3: #5da3b4;
  --blue-4: #0d7594;
}

[data-theme='dark'] {
  --base-text: #f0f0f0;
  --base-bg: #333;
}

@media (prefers-color-scheme: dark) {
  [data-theme='os'] {
    --base-text: #f0f0f0;
    --base-bg: #333;
  }
}
// !!IMPORTANT!! End of Creator dynamic content
