/* stylelint-disable */
@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$min-height-footer: 20.4rem;

/* FOOTER
========================================================================== */
.organisms__footer {
  position: relative;

  .section-footer {
    display: grid;
    gap: $s-lg;
    padding: 0;
  }

  .footer {
    overflow: hidden;
    padding: $s-md $s-md $s-xl $s-md;

    @include gt-tablet {
      padding: $s-xl $grid-padding-desktop $s-xxl $grid-padding-desktop;
    }
  }

  .footer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo__image {
    width: 10.814rem;
    height: 2.8rem;
  }

  /* CONTENT
  ========================================================================== */
  .footer-content {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .footer-images {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: $s-lg;

    @include gt-tablet {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    .google_app_text_div {
      display: flex;
      gap: $s-lg;
      flex-direction: column;

      .google_app_div {
        display: flex;
        gap: $s-md;
       }
     }

   .instagram_facebook_div {
    display: flex;
    gap: $s-md;
   }

    .google_play {
      width: 13.4rem;
      height: 3.97rem;
    }
    .app_store {
      width: 11.966rem;
      height: 4rem;
    }
    .instagram_facebook {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  .footer-text {
    font-size: $text-size-micro;
    line-height: $text-line-height-default;
  }

  .footer-content__content {
    display: flex;
    flex-direction: column;


    @include gt-tablet {
      flex-direction: row;
      align-items: center;
    }
  }

  .footer-secondary-wrapper {
    padding: $grid-padding $grid-padding $s-xl $grid-padding;

    @include gt-tablet {
      padding: $s-s $grid-padding-desktop $s-s $grid-padding-desktop;
    }
  }

  .section-links-list {
    padding: 0;
  }
  /* NAVIGATION
  ========================================================================== */
  .footer-links {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-tablet {
      flex-direction: row;
      gap: $s-lg;
    }
  }


  .footer-links-list  {
    padding: 0;
    margin: auto;
    padding: $s-md $s-md $s-xl $s-md;

    @include gt-tablet {
      padding: $s-s $grid-padding-desktop $s-s $grid-padding-desktop;
    }
  }

  /* SECONDARY NAVIGATION
  ========================================================================== */
  .links-list {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-tablet {
      flex-direction: row;
      gap: $s-lg;

      > li:last-child  > a > span {
        width: 220px;
      }
    }
  }
}
